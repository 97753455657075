import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import IdeetippList from "../components/IdeetippList"
import SEO from "../components/SEO"

const About = ({
  data: {
    allContentfulIdeetipp: { nodes: ideetipp },
  },
}) => {
  return (
    <Layout>
      <SEO title="About" />
      <main className="page">
        <section className="about-page">
          <article>
            <h2>Über uns</h2>
            <div className="text">

              Ideetipp ist die Online-Adresse für alle Ihre Bedürfnisse. 
              Von Marken, Produkten bis hin zu Tipps und Tricks – wir sind Ihre Anlaufstelle für alle Ideen und Antworten auf Ihre alltäglichen Probleme. 
              Bleiben Sie mit den neuesten Trends auf dem Laufenden. Wir halten Sie über die neuesten Marken, Produkte, Trends und Ideen auf dem Laufenden, 
              die Ihnen das Leben erleichtern können.

            </div>
            <br />
            {/*<p>
              <Link to="/contact" className="btn">
                Kontakt
              </Link>
            </p>*/}
          </article>
          <StaticImage
            src="../assets/images/main.jpg"
            alt="food"
            className="about-img"
            placeholder="tracedSVG"
          />
        </section>
        <section className="featured-recipes">
          <h5>Schau dir die tollen Tipps und Ideen an!</h5>
          <IdeetippList ideetipp={ideetipp} />
        </section>
      </main>
    </Layout>
  )
}

export const data = graphql`
  {
    allContentfulIdeetipp(
      sort: { fields: createdAt, order: DESC }
      filter: { featured: { eq: true } }
      limit: 3
    ) {
      nodes {
        id
        title
        createdAt
        subtitle
        durationTime
        prepTime
        image {
          id
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`

export default About
